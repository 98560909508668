import { lazy,Suspense } from 'react';
import './App.css';
import Navbar from './components/pages/Navbar';

import ChatBot from "./components/ui/ChatBot";
import Footer from './components/pages/Footer';
import { Route, Routes } from "react-router-dom";
// import {
//   Modal,
//   Ripple,
//   initTE,
// } from "tw-elements";


const Home = lazy(() => import('./components/pages/Home'));
const Services  = lazy(() => import('./components/pages/Services'));
const Calculator = lazy(() => import( './components/pages/Calculator'));
const Branches = lazy(() => import( './components/pages/Branches'));
const Tracking = lazy(() => import( './components/pages/TrackingForm'));
const SeaFreight = lazy(() => import(  './components/pages/SeaFreight'));
const AirFreight = lazy(() => import(  './components/pages/AirFreight'));
const LandFreight = lazy(() => import(  './components/pages/LandFreight'));
const ScheduleForm = lazy(() => import( './components/pages/ScheduleForm'));
const ContactUs = lazy(() => import( './components/pages/ContactUs'));
const NotFound = lazy(() => import( './components/pages/NotFound'));

// import reviewSVG from "./components/img/review.svg";


//import MessengerCustomerChat from 'react-messenger-customer-chat';



function App() { 
//initTE({ Modal, Ripple });
  return (
    <div className="bg-[#f5f5f5]">
      <header className="min-h-[5vh] lg:sticky top-0 z-50"><Navbar /></header>      
        <div className="min-h-[70vh] lg:relative">
            <Routes>
                <Route path='*' element={<NotFound />}  />
                <Route path="/" element={<Suspense fallback={<div> </div>}><Home /></Suspense>} />  
                <Route path="/services" element={<Suspense fallback={<div> </div>}><Services /></Suspense>} />
                <Route path="/seafreight" element={<Suspense fallback={<div> </div>}><SeaFreight /></Suspense>} />
                <Route path="/airfreight" element={<Suspense fallback={<div> </div>}><AirFreight /></Suspense>} />
                <Route path="/landfreight" element={<Suspense fallback={<div> </div>}><LandFreight /></Suspense>} />
                <Route path="/calculator" element={<Suspense fallback={<div> </div>}><Calculator /></Suspense>} />
                {/*<Route path="/calculator/:ship" element={<Calculator />} />*/}
                <Route path="/branches" element={<Suspense fallback={<div> </div>}><Branches /></Suspense>} />
                <Route path="/tracking/:track" element={<Suspense fallback={<div> </div>}><Tracking /></Suspense>} />
                <Route path="/schedule" element={<Suspense fallback={<div> </div>}><ScheduleForm /></Suspense>} />
                <Route path="/contactus" element={<Suspense fallback={<div> </div>}><ContactUs /></Suspense>} />
            </Routes> 
            <ChatBot />  
        </div>   
        <div className='min-h-[20vh] bg-[#0a4131]'>
        <Footer />
      </div>
    </div>
  );
}

export default App;
