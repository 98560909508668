import React, {useState} from "react";
import { Link,useNavigate } from "react-router-dom";
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import Logo from "../img/logo.png";

import BookNow from "../ui/BookNow";
import TakeSurvery from "../ui/TakeSurvery";
import './Navbar.css'

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav)
    }
    const navigate = useNavigate(); 
    const navHome = () => {
        navigate("/");
    }
    return (
        <div className="bg-[#0a4131]">
            <nav className="flex justify-between items-center h-[5rem] w-[92%] mx-auto">
                <div>
                    <img onClick={navHome} className="w-16 cursor-pointer" src={Logo} alt="..." />
                </div>
                <div className="nav-links duration-500 md:static absolute md:min-h-fit min-h-[60vh] left-0 top-[-100%] md:w-auto w-full flex items-center px-5">
                    <ul className="uppercase text-xl flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-8 cursor-pointer">
                        <li className="text-white hover:text-gray-500"><Link to="/">Home</Link></li>
                        <li className="text-white hover:text-gray-500"><Link to="/tracking/0">Track</Link></li>
                        <li className="dropdown relative">
                            <button className="uppercase text-white hover:text-gray-500">Services</button>
                            {/* dropdown menu */}
                            <div className="dropdown-menu absolute hidden h-auto left-0 rounded-md p-2">
                                <ul className="bg-[#0a4131] rounded-md space-y-2 lg:w-48">
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/airfreight">Air Freight</Link>
                                    </li>
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/seafreight">Sea Freight</Link>
                                    </li>
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/landfreight">Land Freight</Link>
                                    </li>
                                </ul>
                            </div>
                            {/* dropdown menu */}
                        </li>
                        <li className="text-white hover:text-gray-500"><Link to="/calculator">Calculator</Link></li>
                        {/*<li className="dropdown relative">
                            <button className="uppercase text-white hover:text-gray-500">Calculator</button>
                            <div className="dropdown-menu absolute hidden h-auto left-0 rounded-md p-2">
                                <ul className="bg-[#0a4131] rounded-md space-y-2 lg:w-48">
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/calculator/JCS">JCS</Link>
                                    </li>
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/calculator/JEX">JEX</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>*/}
                        <li className="text-white hover:text-gray-500"><Link to="/branches">Branches</Link></li>
                        <li className="text-white hover:text-gray-500"><Link to="/contactus">Contact Us</Link></li>
                    </ul>
                </div>
                <div className="flex items-center gap-6">
                    <BookNow />
                </div>
                <div onClick={handleNav} className='block md:hidden'>
                    {!nav ? <AiOutlineMenu size={20}/>:<AiOutlineClose size={20} />}                    
                </div>
                <div className={!nav ? 'fixed left-[-100%]' : 'absolute right-0 top-20 w-[100%] h-full border-r border-r-gray-900  bg-[#0c6e4f] ease-in-out duration-500 z-[1000]'}>
                    <ul className="uppercase text-white p-4">
                        <li className="p-4 border-b border-gray-600 hover:text-gray-500"><Link to="/" onClick={handleNav}>Home</Link></li>                        
                        <li className="p-4 border-b border-gray-600 hover:text-gray-500"><Link to="/tracking/0" onClick={handleNav}>Track</Link></li>
                        <li className="dropdown p-4 border-b border-gray-600 hover:text-gray-500">
                            <button className="uppercase text-white hover:text-gray-500">Services</button>
                            {/* dropdown menu */}
                            <div className="dropdown-menu p-2 hidden">
                                <ul className="bg-[#0c6e4f] lg:w-48">
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/airfreight" onClick={handleNav}>Air Freight</Link>
                                    </li>
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/seafreight" onClick={handleNav}>Sea Freight</Link>
                                    </li>
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/landfreight" onClick={handleNav}>Land Freight</Link>
                                    </li>
                                </ul>
                            </div>
                            {/* dropdown menu */}
                        </li>
                        <li className="p-4 border-b border-gray-600 hover:text-gray-500"><Link to="/calculator" onClick={handleNav}>Calculator</Link></li>
                        {/*<li className="p-4 border-b border-gray-600 hover:text-gray-500">
                            <button className="uppercase text-white hover:text-gray-500">Calculator</button>
                            <div className="dropdown-menu p-2 hidden">
                                <ul className="bg-[#0c6e4f] lg:w-48">
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/calculator/JCS" onClick={handleNav}>JCS</Link>
                                    </li>
                                    <li className="flex p-2 font-medium text-white hover:text-gray-500 hover:cursor-pointer" >
                                        <Link to="/calculator/JEX" onClick={handleNav}>JEX</Link>
                                    </li>
                                </ul>
                            </div>
    </li>*/}
                        <li className="p-4 border-b border-gray-600 hover:text-gray-500"><Link to="/branches" onClick={handleNav}>Branches</Link></li>
                        <li className="p-4 border-b border-gray-600 hover:text-gray-500"><Link to="/contactus" onClick={handleNav}>Contact Us</Link></li>
                    </ul>
                </div>
            </nav>    
        </div>    
    )
}

export default Navbar;