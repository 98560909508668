import { Link } from "react-router-dom";
//import {Modal,Ripple,initTE} from "tw-elements";

const BookNow =() => {
    /*const handleBookNow=()=>{
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdDf1wjuK_NVehsWydGbI3GmpHqKUTaC5OadUOnbHKLHSYzwg/viewform');
    }*/
    //initTE({ Modal, Ripple });
    return(
        <>
            <button className="bg-[#f0ec2f] text-black px-5 py-2 rounded-md"><Link to="/schedule">Book Now!</Link></button>
            {/*<button onClick={handleBookNow} className="bg-[#f0ec2f] text-black font-semibold px-5 py-2 rounded-md">Book Now!</button>*/}
        </>
    )
}

export default BookNow;