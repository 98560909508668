import React from "react";
import Logo from "../img/logo.png";
import {FaFacebookSquare, FaInstagram} from 'react-icons/fa';
import {MdOutlineEmail} from 'react-icons/md';

const Footer = () => {
    const navFacebook = (e) =>{ 
        window.open("https://www.facebook.com/JadesCargo");
    }
    const navInstagram = (e) =>{ 
        window.open("https://www.instagram.com/jadescargo/?igshid=NTc4MTIwNjQ2YQ%3D%3D&fbclid=IwAR12OAJwX8Xi0c2e4zdke28h4l5JYJiEl2aLYzIqMMoXhKh08b2qT8RMBDo");
    }
    return (
        <div className="max-w-[1240px] mx-auto py-4 px-4 gap-10 grid grid-cols-1 md:grid-cols-4 text-gray-300">
            <div className="lg:col-span-3">                    
                <img className="w-20 float-left mr-4" src={Logo} alt="..." />
                <p className='p-2 text-[10px] text-justify'><h5>For over three decades, our logistics company has been a trusted partner in the Philippines, providing reliable and efficient logistics solutions to businesses across various industries. Since our founding in 1988, we have built a reputation for excellence in logistics, offering a comprehensive range of services, including air and sea freight, trucking, warehousing, and more. With our commitment to customer satisfaction and our wealth of experience in the industry, we continue to be a leading player in the logistics landscape of the Philippines.</h5>
                </p>
            </div>
            <div className="p-2 grid items-baseline">
                <div className="grid lg:grid-cols-1">
                    <div>
                        <h6 className='font-medium text-sm text-gray-400'>Contact Us</h6>
                        <div className="flex gap-2">
                            <FaFacebookSquare className="hover:cursor-pointer" onClick={navFacebook} size={25}/>
                            <FaInstagram className="hover:cursor-pointer" onClick={navInstagram} size={25}/>
                        </div>
                        <ul className="flex gap-2"><MdOutlineEmail size={25} />
                            <li className="items-baseline py-2 text-sm">support@jadescargo.net</li>
                        </ul>
                    </div>
                </div>
            </div>     
        </div>
    )
}

export default Footer