import { useRef, useEffect } from "react";
import reviewSVG from "../img/review.svg";
import msgrSVG from "../img/fb-messenger.svg";

const ChatBot = () => {
        const MessengerRef = useRef();
        const handleReview=()=>{
          window.open('https://forms.gle/KHjjzRbKfuwqnw7a7');
        }
        const handleMsgr=()=>{
            window.open('https://m.me/jadescargo');
          }
        useEffect(() => {
            MessengerRef.current.setAttribute('page_id', '336592186441540');
            MessengerRef.current.setAttribute('attribution', 'biz_inbox');
        
            window.fbAsyncInit = function () {
                window.FB.init({
                    xfbml: true,
                    version: 'v17.0',
                });
            };
            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));
        }, []);
        return (
            <>
                <div id="fb-root"></div>  
                <div ref={MessengerRef} id="fb-customer-chat" className="fb-customerchat"></div> 
                {/*</div><div className="fb-customerchat">*/}
                <div className="bottom-0 sticky right-10 w-full flex justify-end pr-40">
                    <img className="cursor-pointer" src={msgrSVG} height={45} width={45} onClick={handleMsgr} />   
                </div> 
            </>
        );
    }; 
export default ChatBot;